import React, { Fragment, useEffect, useRef, useState } from 'react';
import { MenuItem } from 'utils/menu';
import MenuItemChild from 'components/menu/MenuItemChild';
import MenuListItem from 'components/menu/MenuListItem';

export default function Menu(props: {
	parentItem: MenuItem;
	openedItemPosition: string;
	setOpenedItemPosition: (pos: string) => void;
}) {
	const [openedChildPosition, setOpenedChildPosition] = useState(props.parentItem.position);
	const [parentPosition, setParentPosition] = useState(props.parentItem.position);
	const [isOpen, setIsOpen] = useState(props.openedItemPosition === props.parentItem.position);

	useEffect(() => {
		if (!isOpen) setOpenedChildPosition(props.parentItem.position);
	}, [isOpen, props.parentItem.position]);

	function isMenuClicked(target: HTMLElement | null) {
		while (target) {
			if (
				target.classList.contains('menu-list-item') ||
				target.classList.contains('menu-item-child')
			) {
				return true;
			}
			target = target.parentElement;
		}
		return false;
	}
	function useOutsideAlerter(ref: React.MutableRefObject<HTMLDivElement | null>) {
		useEffect(() => {
			function handleClickOutside(event: MouseEvent) {
				const target = event.target as HTMLElement;
				if (ref.current && !ref.current.contains(target) && !isMenuClicked(target)) {
					setIsOpen(false);
				}
			}

			document.addEventListener('click', handleClickOutside);
			return () => {
				document.removeEventListener('click', handleClickOutside);
			};
		}, [ref]);
	}

	const menuRef = useRef(null);
	useOutsideAlerter(menuRef);

	const toggleMenu = () => {
		if (props.parentItem.children) {
			props.setOpenedItemPosition(!isOpen ? props.parentItem.position : '');
			setIsOpen(!isOpen);
		}
	};

	return (
		<div className="position-relative" ref={menuRef}>
			<button
				className={'menu-button link d-flex align-items-center' + (isOpen ? ' open' : '')}
				type="button"
				onClick={() => toggleMenu()}>
				{props.parentItem.position === '000' && props.parentItem.role ? (
					<>
						<span className="me-2">{props.parentItem.name}</span>
						<span className="badge rounded-pill bg-primary-shade color-white fw-normal me-2 lh-sm">
							{props.parentItem.role}
						</span>
					</>
				) : (
					<span className="me-2">{props.parentItem.menu_item_name}</span>
				)}
				{props.parentItem.children && <i className="bi-chevron-down" />}
			</button>
			{props.parentItem.children && isOpen && (
				<div className="menu-dropdown">
					{openedChildPosition === props.parentItem.position && (
						<ul>
							{props.parentItem.children.map((child) => (
								<Fragment key={child.position}>
									{child.page === 'profile_settings' && <div className="menu-split" />}
									<MenuListItem
										setParentPosition={setParentPosition}
										item={child}
										setOpenedChildPosition={setOpenedChildPosition}
										ancestor={props.parentItem.position}
										setIsOpen={setIsOpen}
									/>
								</Fragment>
							))}
						</ul>
					)}
					{openedChildPosition !== props.parentItem.position && (
						<MenuItemChild
							parentPosition={parentPosition}
							setParentPosition={setParentPosition}
							item={
								props.parentItem.children.find((c) => c.position === openedChildPosition) ??
								props.parentItem.children[0]
							}
							openedChildPosition={openedChildPosition}
							setOpenedChildPosition={setOpenedChildPosition}
							setIsOpen={setIsOpen}
						/>
					)}
				</div>
			)}
		</div>
	);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SortingData {
	colId: string;
	sort: 'asc' | 'desc' | null | undefined;
}

export interface gridFilterState {
	keys: string[];
	filterData: { [key: string]: object };
	internalKeys: string[];
	internalFilterData: { [key: string]: object };
	sortingKeys: string[];
	sortingData: { [key: string]: SortingData[] };
}

const filterDataKey = 'filterData';
const filterDataKeysKey = `${filterDataKey}.keys`;
const filterDataInternalKeysKey = `${filterDataKey}.internalKeys`;
const filterDataSortingKeysKey = `${filterDataKey}.sortingKeys`;

const filterKeys = JSON.parse(localStorage.getItem(filterDataKeysKey) ?? '[]');
const filterInternalKeys = JSON.parse(localStorage.getItem(filterDataInternalKeysKey) ?? '[]');
const sortingKeys = JSON.parse(localStorage.getItem(filterDataSortingKeysKey) ?? '[]');

const initialState: gridFilterState = {
	keys: filterKeys,
	filterData: filterKeys
		.map((key: string) => JSON.parse(localStorage.getItem(`${filterDataKey}.${key}`) ?? '{}'))
		.reduce((acc: { [key: string]: object }, obj: object, i: number) => {
			acc[filterKeys[i]] = obj;
			return acc;
		}, {}),
	internalKeys: filterInternalKeys,
	internalFilterData: filterInternalKeys
		.map((key: string) => JSON.parse(localStorage.getItem(`${filterDataKey}.${key}`) ?? '{}'))
		.reduce((acc: { [key: string]: object }, obj: object, i: number) => {
			acc[filterInternalKeys[i]] = obj;
			return acc;
		}, {}),
	sortingKeys: sortingKeys,
	sortingData: sortingKeys
		.map(
			(key: string) =>
				JSON.parse(localStorage.getItem(`${filterDataKey}.sort.${key}`) ?? '[]') as SortingData[]
		)
		.reduce((acc: { [key: string]: SortingData[] }, obj: SortingData[], i: number) => {
			acc[sortingKeys[i]] = obj;
			return acc;
		}, {})
};

const slice = createSlice({
	name: 'gridFilter',
	initialState,
	reducers: {
		setGridFilter(state, action: PayloadAction<{ key: string; data: object }>) {
			const lsItemKey = `${filterDataKey}.${action.payload.key}`;
			state.filterData[action.payload.key] = action.payload.data;
			state.keys = Object.keys(state.filterData);
			localStorage.setItem(filterDataKeysKey, JSON.stringify(state.keys));
			localStorage.setItem(lsItemKey, JSON.stringify(action.payload.data));
		},
		emptyGridFilter(state, action: PayloadAction<string>) {
			const lsItemKey = `${filterDataKey}.${action.payload}`;
			if (localStorage.getItem(lsItemKey)) {
				delete state.filterData[action.payload];
				state.keys = Object.keys(state.filterData);
				localStorage.setItem(filterDataKeysKey, JSON.stringify(state.keys));
				localStorage.removeItem(lsItemKey);
			}
		},
		setGridInternalFilter(state, action: PayloadAction<{ key: string; data: object | null }>) {
			const lsItemKey = `${filterDataKey}.${action.payload.key}`;
			if (action.payload.data !== null) {
				state.internalFilterData[action.payload.key] = action.payload.data;
				localStorage.setItem(lsItemKey, JSON.stringify(action.payload.data));
			} else {
				delete state.internalFilterData[action.payload.key];
				localStorage.removeItem(lsItemKey);
			}
			state.internalKeys = Object.keys(state.internalFilterData);
			localStorage.setItem(filterDataInternalKeysKey, JSON.stringify(state.internalKeys));
		},
		emptyGridInternalFilter(state, action: PayloadAction<string>) {
			const lsItemKey = `${filterDataKey}.${action.payload}`;
			if (localStorage.getItem(lsItemKey)) {
				delete state.internalFilterData[action.payload];
				state.internalKeys = Object.keys(state.internalFilterData);
				localStorage.setItem(filterDataInternalKeysKey, JSON.stringify(state.internalKeys));
				localStorage.removeItem(lsItemKey);
			}
		},
		setGridSorting(state, action: PayloadAction<{ gridIdentifier: string; data: SortingData }>) {
			const lsItemKey = `${filterDataKey}.sort.${action.payload.gridIdentifier}`;
			const data =
				state.sortingData[action.payload.gridIdentifier]?.filter(
					(sd) => sd.colId != action.payload.data.colId
				) ?? [];
			if (action.payload.data.sort) {
				data.push(action.payload.data);
				state.sortingData[action.payload.gridIdentifier] = data;
				localStorage.setItem(lsItemKey, JSON.stringify(data));
			}
			if (data.length === 0) {
				delete state.sortingData[action.payload.gridIdentifier];
				localStorage.removeItem(lsItemKey);
			}
			state.sortingKeys = Object.keys(state.sortingData);
			localStorage.setItem(filterDataSortingKeysKey, JSON.stringify(state.sortingKeys));
		},
		emptyGridSorting(state, action: PayloadAction<string>) {
			const lsItemKey = `${filterDataKey}.sort.${action.payload}`;
			if (localStorage.getItem(lsItemKey)) {
				delete state.sortingData[action.payload];
				state.sortingKeys = Object.keys(state.sortingData);
				localStorage.setItem(filterDataSortingKeysKey, JSON.stringify(state.sortingKeys));
				localStorage.removeItem(lsItemKey);
			}
		}
	}
});

export default slice.reducer;
export const {
	setGridFilter,
	emptyGridFilter,
	setGridInternalFilter,
	emptyGridInternalFilter,
	setGridSorting,
	emptyGridSorting
} = slice.actions;

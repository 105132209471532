import React from 'react';
import { AppComponent, ComponentDefinition } from 'utils/components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Redirect(props: {
	component: AppComponent;
	definition: ComponentDefinition | undefined;
}) {
	const { t } = useTranslation();
	const variables = JSON.parse(props.component.variables);

	return (
		<Link className={(props.definition?.class ?? '') + ' my-4'} to={variables.i_page}>
			{t(props.definition?.component_body ?? '')}
		</Link>
	);
}

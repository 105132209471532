import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from 'assets/svgs/error.svg';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
	const { t } = useTranslation();

	return (
		<div className="row">
			<div className="col-12 d-flex justify-content-center pt-5 mt-5">
				<div className="text-center">
					<div className="color-red-dark error-icon pb-4">
						<ErrorIcon />
					</div>
					<h1>{t('general.error.something_went_wrong')}</h1>
					<p className="fs-5 mt-3">{t('general.error.working_phrase')}</p>
					<Link to={'/'} className="btn btn-primary btn-lg mt-4">
						{t('general.error.return_to_homepage')}
					</Link>
				</div>
			</div>
		</div>
	);
}

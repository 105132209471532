import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TranslationsState {
	hash: string;
	translations: { [key: string]: string };
	last_update: string | null;
}

const localStorageKey = 'translation';

const initialState: { [key: string]: TranslationsState } = localStorage.getItem(localStorageKey)
	? JSON.parse(localStorage.getItem(localStorageKey) ?? '{}')
	: {};

const slice = createSlice({
	name: 'translation',
	initialState,
	reducers: {
		setTranslations(
			state,
			action: PayloadAction<{
				locale: string;
				translations: { [key: string]: string };
				hash: string;
			}>
		) {
			state[action.payload.locale] = {
				hash: action.payload.hash,
				translations: action.payload.translations,
				last_update: new Date().toString()
			};

			localStorage.setItem(localStorageKey, JSON.stringify(state));
		},
		invalidateTranslations(state) {
			if (state['en'] !== undefined) {
				state['en'].last_update = null;
				localStorage.setItem(localStorageKey, JSON.stringify(state));
			}
		}
	}
});

export default slice.reducer;
export const { setTranslations, invalidateTranslations } = slice.actions;

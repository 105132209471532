import React, { useEffect, useState } from 'react';
import { LoginResponse } from 'utils/auth';
import { isUserLoggedIn, sendHttpRequest, setAuthItem } from 'utils/httpRequestManager';
import { AxiosError } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/Forms/FormComponents/Input';
import PasswordInput from 'components/Forms/FormComponents/PasswordInput';
import { useDispatch } from 'react-redux';
import { setUser } from 'state/userSlice';
import Alert from 'components/parts/Alert';
import Loading from 'components/parts/Loading';

export default function Login() {
	const [error, setError] = useState({ type: '', message: '' });
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	type Inputs = {
		email: string;
		password: string;
	};

	useEffect(() => {
		if (isUserLoggedIn()) {
			navigate('/');
		}
	}, [navigate]);

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<Inputs>();

	const removeError = () => {
		setError({ type: '', message: '' });
	};

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		setLoading(true);
		sendHttpRequest('POST', '/login', data, navigate, dispatch)
			.then((response) => {
				const item = response.data as LoginResponse;

				setAuthItem(item);

				dispatch(setUser(item.user));

				navigate('/');
			})
			.catch((error) => {
				if (error instanceof AxiosError) {
					if ([401, 403].includes(error.response?.status ?? 0)) {
						setError({
							type: 'custom',
							message: error.response?.data.message
						});
					} else {
						setError({
							type: 'error',
							message: error.response?.data.message ?? 'An error occurred'
						});
					}
				}
			})
			.finally(() => setLoading(false));
	};

	return (
		<>
			<div className="row justify-content-center">
				<div className="login-wrapper py-5">
					<h1 className="text-center mb-5">Sign in</h1>
					{loading ? (
						<Loading />
					) : (
						<>
							{error.type !== 'custom' && error.message !== '' && (
								<Alert type="danger" message={error.message} />
							)}
							<form onSubmit={handleSubmit(onSubmit)} noValidate className="position-relative">
								<Input<Inputs>
									name="email"
									label="Email address"
									type="email"
									required={true}
									register={register}
									error={errors.email}
									customError={error.type === 'custom' ? error.message : undefined}
									onChange={removeError}
								/>

								<PasswordInput<Inputs>
									name="password"
									label="Password"
									required={true}
									register={register}
									error={errors.password}
									customError={error.type === 'custom' ? error.message : undefined}
									autoComplete={'current-password'}
									onChange={removeError}
								/>

								<button type="submit" className="btn btn-primary w-100 mt-1">
									Sign in
								</button>
							</form>
							<div className="d-flex py-4 justify-content-center">
								<Link to={'/forgot-password'}>Forgot password</Link>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

import React from 'react';
import { AppComponent, ComponentDefinition } from 'utils/components';
import BreadCrumb from 'components/parts/BreadCrumb';
import Button from 'components/parts/Button';
import Col from 'components/parts/Col';
import Dropdown from 'components/parts/Dropdown';
import Form from 'components/parts/Form';
import FormChangePassword from 'components/parts/FormChangePassword';
import Grid from 'components/parts/Grid';
import InfoPanel from 'components/parts/InfoPanel';
import ListItem from 'components/parts/ListItem';
import Redirect from 'components/parts/Redirect';
import Row from 'components/parts/Row';
import Tab from 'components/parts/Tab';
import Table from 'components/parts/Table';
import Text from 'components/parts/Text';
import TextInput from 'components/parts/TextInput';
import ProductsForm from 'components/Forms/ProductsForm';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';
import ManualOrdersUploadForm from 'components/Forms/ManualOrdersUploadForm';

export default function DynamicComponent(props: {
	component: AppComponent;
	definition: ComponentDefinition | undefined;
	has_definitions: boolean;
}) {
	const componentDefinitions = useSelector((state: RootState) => state.components.components);
	let definition = props.definition;

	if (!props.has_definitions) {
		definition = componentDefinitions.find((c) => c.i_component == props.component.i_component);
	}

	switch (definition?.i_component_type) {
		case 'a_create_products':
			return <ProductsForm component={props.component} />;
		case 'breadcrumb_start':
			return <BreadCrumb component={props.component} />;
		case 'button':
			return <Button component={props.component} definition={definition} />;
		case 'col_start':
			return <Col component={props.component} definition={definition} />;
		case 'dropdown':
			return <Dropdown component={props.component} />;
		case 'form':
			return <Form component={props.component} />;
		case 'form_change_password':
			return <FormChangePassword component={props.component} />;
		case 'grid':
			return <Grid component={props.component} definition={definition} />;
		case 'info_panel':
			return <InfoPanel component={props.component} />;
		case 'link':
			return <></>;
		case 'm_list':
			return <ListItem component={props.component} />;
		case 'redirect':
			return <Redirect component={props.component} definition={definition} />;
		case 'row_start':
			return <Row component={props.component} />;
		case 'tab':
			return <Tab component={props.component} definition={definition} />;
		case 'table':
			return <Table component={props.component} />;
		case 'text':
			return <Text component={props.component} />;
		case 'text_input':
			return <TextInput component={props.component} />;
		case 'orders_upload':
			return <ManualOrdersUploadForm />;
	}

	if (props.has_definitions) {
		console.warn(
			'Unknown component:',
			props.component.i_component,
			'type:',
			definition?.i_component_type
		);
	}

	return <></>;
}

import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { useNavigate } from 'react-router-dom';

export default function ManualOrdersUploadForm() {
	const { t } = useTranslation();
	const [showOrdersUploadModal, setShowOrdersUploadModal] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [file, setFile] = useState<null | File>(null);
	const [uploading, setUploading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const navigate = useNavigate();

	function openFileInput() {
		(fileInputRef.current as HTMLInputElement).click();
	}

	function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
		const selectedFile = e.target.files ? e.target.files[0] : null;
		if (selectedFile) {
			setFile(selectedFile);
		}
	}

	function onSubmit(e: React.FormEvent) {
		e.preventDefault();

		if (!file) {
			alert(t('general.phrases.no_file_selected'));
			return;
		}

		setErrorMessage('');
		setUploading(true);
		const data = { file: file };

		sendHttpRequest('POST', '/order/dops', data, navigate)
			.then(() => {
				window.location.reload();
			})
			.catch((error) => {
				setErrorMessage(error.response.data.message);
			})
			.finally(() => {
				setUploading(false);
			});
	}

	return (
		<>
			<button
				type="button"
				className="btn btn-primary my-3 orders-upload-modal-trigger"
				onClick={() => setShowOrdersUploadModal(true)}>
				{t('general.phrases.manual_orders_upload')}
			</button>
			<Modal show={showOrdersUploadModal} onHide={() => setShowOrdersUploadModal(false)}>
				<Modal.Header closeButton>
					<h2 className="fs-5 m-0">{t('general.phrases.manual_orders_upload')}</h2>
				</Modal.Header>

				<Modal.Body>
					<form onSubmit={onSubmit} encType="multipart/form-data">
						<div className="d-flex justify-content-center">
							<button
								type="button"
								className="btn btn-outline-primary orders-upload-button"
								onClick={openFileInput}>
								<i className="bi bi-upload" />
								<span>{t('general.phrases.upload_xlsx')}</span>
							</button>
							<input
								ref={fileInputRef}
								type="file"
								name="orders-file"
								className="d-none"
								onChange={handleFileChange}
								accept=".xlsx"
							/>
						</div>
						<div>
							<p className="my-3 text-center">
								{!file ? t('general.phrases.no_file_selected') : file.name}
							</p>
							{errorMessage !== '' && (
								<p className="my-3 text-center text-danger">{errorMessage}</p>
							)}
						</div>
						<div className="pt-3 d-flex justify-content-end">
							<button type="submit" className="btn btn-primary px-4" disabled={uploading}>
								{uploading ? t('general.phrases.uploading') : t('general.phrases.upload')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
}

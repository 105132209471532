import React from 'react';
import { ToastContainer } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import NotificationToast from 'components/parts/NotificationToast';

export default function NotificationContainer() {
	const notifications = useSelector((state: RootState) => state.notification.notifications);

	return (
		<div>
			<ToastContainer className="notifications fixed-bottom m-3">
				{notifications.map((notification, index) => (
					<NotificationToast notification={notification} key={index} />
				))}
			</ToastContainer>
		</div>
	);
}

import React, { useState, useEffect, useRef } from 'react';

export default function AutocompleteDropdown<T>(props: {
	autocompleteOptions: T[];
	onOptionPick: (option: T) => void;
	optionRenderer: (option: T) => JSX.Element;
	inputRef?: React.MutableRefObject<HTMLInputElement | null>;
	inputId: string;
	disabled: boolean;
	inputValue: string;
	label: string;
	setInputValue: (inputValue: string) => void | Promise<void>;
	isFocused: boolean;
	setIsFocused: (isFocused: boolean) => void;
	dropdownClassName?: string;
}) {
	const [focusedOption, setFocusedOption] = useState(-1);

	const altRef = useRef(null as HTMLInputElement | null);
	const inputRef = props.inputRef ?? altRef;

	function onKeyDown(e: React.KeyboardEvent, target = null as T | null) {
		if (target) {
			// the target is one of the list items
			e.preventDefault();
			if (e.key === 'Enter') {
				props.onOptionPick(target);
			}
		}

		let newFocusedItemOption = focusedOption;
		if (e.key === 'ArrowDown') {
			newFocusedItemOption = Math.min(props.autocompleteOptions.length - 1, focusedOption + 1);
			optionsRefs.current[newFocusedItemOption]?.focus();

			setFocusedOption(newFocusedItemOption);
		} else if (e.key === 'ArrowUp') {
			newFocusedItemOption = Math.max(-1, focusedOption - 1);
			if (newFocusedItemOption !== -1) {
				optionsRefs.current[newFocusedItemOption].focus();
			} else {
				inputRef.current?.focus();
			}

			setFocusedOption(newFocusedItemOption);
		} else {
			setFocusedOption(-1);
		}

		if (['Tab', 'Escape'].includes(e.key)) {
			props.setIsFocused(false);
			inputRef.current?.focus();
		}
	}

	function useHandleDocumentClick() {
		useEffect(() => {
			function handleClickOutside(event: MouseEvent) {
				const target = event.target as HTMLElement;
				if (!isDropdownClicked(target)) {
					props.setIsFocused(false);
				}
			}

			document.addEventListener('click', handleClickOutside);
			return () => {
				document.removeEventListener('click', handleClickOutside);
			};
		}, []);
	}

	function isDropdownClicked(target: HTMLElement | null) {
		while (target) {
			if (target.classList.contains('autocomplete-dropdown')) {
				return true;
			}
			target = target.parentElement;
		}
		return false;
	}

	useHandleDocumentClick();

	const optionsRefs = useRef([] as HTMLLIElement[]);

	return (
		<div className="position-relative">
			<div className="form-floating">
				<input
					id={props.inputId}
					className="form-control"
					ref={inputRef}
					name={props.inputId}
					type="text"
					required={true}
					value={props.inputValue}
					disabled={props.disabled}
					autoComplete="off"
					onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
						props.setInputValue(e.target.value);
					}}
					onKeyDown={(e) => onKeyDown(e)}
				/>
				<label>{props.label}</label>
			</div>
			{props.isFocused && props.autocompleteOptions.length > 0 && (
				<ul className={'autocomplete-dropdown ' + (props.dropdownClassName ?? '')}>
					{props.autocompleteOptions.map((option, j) => (
						<li
							tabIndex={0}
							ref={(el) => (optionsRefs.current[j] = el as HTMLLIElement)}
							className="autocomplete-dropdown-content font-small py-1 px-2 d-flex justify-content-between align-items-baseline"
							onClick={() => props.onOptionPick(option)}
							onKeyDown={(e) => onKeyDown(e, option)}
							key={j}>
							{props.optionRenderer(option)}
						</li>
					))}
				</ul>
			)}
		</div>
	);
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { invalidateTranslations } from 'state/translationSlice';
import { Dispatch } from '@reduxjs/toolkit';

export const languages = [
	{ lang: 'en', code: 'en-us', name: 'English (en)' },
	{ lang: 'bg', code: 'bg', name: 'Български (bg)' },
	{ lang: 'bs', code: 'ba', name: 'Bosanski (bs)' },
	{ lang: 'cs', code: 'cz', name: 'Česky (cs)' },
	{ lang: 'da', code: 'dk', name: 'Dansk (da)' },
	{ lang: 'de', code: 'de', name: 'Deutsch (de)' },
	{ lang: 'el', code: 'gr', name: 'Νέα Ελληνικά (el)' },
	{ lang: 'es', code: 'es', name: 'Español (es)' },
	{ lang: 'et', code: 'ee', name: 'Eesti (et)' },
	{ lang: 'fi', code: 'fi', name: 'Suomi (fi)' },
	{ lang: 'fr', code: 'fr', name: 'Français (fr)' },
	{ lang: 'hr', code: 'hr', name: 'Hrvatski (hr)' },
	{ lang: 'hu', code: 'hu', name: 'Magyar (hu)' },
	{ lang: 'is', code: 'is', name: 'Íslenska (is)' },
	{ lang: 'it', code: 'it', name: 'Italiano (it)' },
	{ lang: 'lt', code: 'lt', name: 'Lietuviškai (lt)' },
	{ lang: 'lv', code: 'lv', name: 'Latviski (lv)' },
	{ lang: 'nb', code: 'no', name: 'Norsk Bokmål (nb)' },
	{ lang: 'nl', code: 'nl', name: 'Nederlands (nl)' },
	{ lang: 'pl', code: 'pl', name: 'Polski (pl)' },
	{ lang: 'pt', code: 'pt', name: 'Português (pt)' },
	{ lang: 'ro', code: 'ro', name: 'Românã (ro)' },
	{ lang: 'ru', code: 'ru', name: 'Русский (ru)' },
	{ lang: 'sk', code: 'sk', name: 'Slovenčina (sk)' },
	{ lang: 'sl', code: 'si', name: 'Slovenščina (sl)' },
	{ lang: 'sr', code: 'rs', name: 'Srpski (sr)' },
	{ lang: 'sv', code: 'se', name: 'Svenska (sv)' }
];
let dispatch: null | Dispatch = null;

export function setDispatcher(d: Dispatch) {
	dispatch = d;
}

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: languages.map((lang) => lang.lang),
		partialBundledLanguages: true,
		ns: [],
		resources: {},
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		},
		saveMissing: true,
		missingKeyHandler: (lngs, ns, key) => {
			sendHttpRequest('POST', '/translations/missing/en/' + key, null).then(() => {
				dispatch?.(invalidateTranslations());
			});
		}
	});

export default i18n;

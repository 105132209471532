import React, { useMemo, useState } from 'react';
import { GpSummaryProcessed } from 'utils/types';
import { addThousandSeparator } from 'utils/string';
import { useTranslation } from 'react-i18next';

export default function GpSummary(props: { gpSummaryProcessed: GpSummaryProcessed[] }) {
	const { gpSummaryProcessed } = props;
	const { t } = useTranslation();

	const [summaryCarouselOffset, setSummaryCarouselOffset] = useState(0);
	const summaryCarouselItemsPerView = 4;

	function summaryCarouselLeft() {
		setSummaryCarouselOffset(Math.max(0, summaryCarouselOffset - 1));
	}

	function summaryCarouselRight() {
		setSummaryCarouselOffset(
			Math.min(gpSummaryProcessed.length - summaryCarouselItemsPerView, summaryCarouselOffset + 1)
		);
	}

	const nPartiesTotal = useMemo(() => {
		return gpSummaryProcessed.reduce((sum, period) => sum + period.parties_number_forecast, 0);
	}, [gpSummaryProcessed]);

	const nRecruitsTotal = useMemo(() => {
		return gpSummaryProcessed.reduce((sum, period) => sum + period.number_of_recruits, 0);
	}, [gpSummaryProcessed]);

	return (
		<div className="week-summary">
			<div className="color-primary-shade">
				<div className="fw-medium">{t('promotion.fields.forecast')}</div>
				<div>{t('promotion.fields.parties')}</div>
				<div>{t('promotion.fields.recruits')}</div>
			</div>
			{gpSummaryProcessed.length <= 5 &&
				gpSummaryProcessed.map((s) => (
					<div key={s.week} className="week-summary-data text-end">
						<div className="color-primary-shade fw-medium">{s.week}</div>
						<div>{addThousandSeparator(s.parties_number_forecast)}</div>
						<div>{addThousandSeparator(s.number_of_recruits)}</div>
					</div>
				))}
			{gpSummaryProcessed.length > 5 && (
				<>
					<button
						type="button"
						disabled={summaryCarouselOffset === 0}
						className="btn-icon p-0"
						onClick={summaryCarouselLeft}>
						<i className="bi bi-chevron-left fs-5" />
					</button>
					{gpSummaryProcessed
						.slice(summaryCarouselOffset, summaryCarouselOffset + summaryCarouselItemsPerView)
						.map((s) => (
							<div key={s.week} className="week-summary-data text-end">
								<div className="color-primary-shade fw-medium">{s.week}</div>
								<div>{addThousandSeparator(s.parties_number_forecast)}</div>
								<div>{addThousandSeparator(s.number_of_recruits)}</div>
							</div>
						))}
					<button
						type="button"
						disabled={
							summaryCarouselOffset === gpSummaryProcessed.length - summaryCarouselItemsPerView
						}
						className="btn-icon p-0"
						onClick={summaryCarouselRight}>
						<i className="bi bi-chevron-right fs-5" />
					</button>
				</>
			)}
			<div className="week-summary-data total text-end">
				<div className="color-primary-shade fw-medium">{t('promotion.fields.total')}</div>
				<div className="fw-medium">{addThousandSeparator(nPartiesTotal)}</div>
				<div className="fw-medium">{addThousandSeparator(nRecruitsTotal)}</div>
			</div>
		</div>
	);
}

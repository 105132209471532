import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuItem } from 'utils/menu';

export const menuItemKey = 'menuItems';

export interface MenuState {
	menu: MenuItem[];
}

const initialState: MenuState = {
	menu: localStorage.getItem(menuItemKey)
		? (JSON.parse(localStorage.getItem(menuItemKey) as string) as MenuItem[])
		: ([] as MenuItem[])
};

const slice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		setMenu(state, action: PayloadAction<MenuItem[]>) {
			const menuItems = action.payload;
			localStorage.setItem(menuItemKey, JSON.stringify(menuItems));
			state.menu = menuItems;
		}
	}
});

export default slice.reducer;
export const { setMenu } = slice.actions;

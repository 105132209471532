import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const promotionStatusItemKey = 'promotionStatusItems';

export interface PromotionState {
	statuses: string[];
}

const initialState: PromotionState = {
	statuses: []
};

const slice = createSlice({
	name: 'promotion',
	initialState,
	reducers: {
		setStatuses(state, action: PayloadAction<string[]>) {
			state.statuses = action.payload;
		}
	}
});

export default slice.reducer;
export const { setStatuses } = slice.actions;

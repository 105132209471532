import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { sendHttpRequest } from 'utils/httpRequestManager';

export type AppComponent = {
	i_component: string;
	variables: string;
	datasource_name: string | null;
	datasource_parameters: string | null;
	children?: AppComponent[];
	row_identifiers?: object;
	action?: string;
};

export type ComponentDefinition = {
	i_component: string;
	i_component_type: string;
	n_component: string | null;
	col: string | null;
	class: string | null;
	columns: string | null;
	component_body: string | null;
	component_parameters: string | null;
};

export async function getPageComponentTree(
	path: string,
	navigate: NavigateFunction,
	dispatch: Dispatch<UnknownAction>
) {
	if (path.charAt(0) === '/') path = path.slice(1);
	const urlParams = new URLSearchParams(window.location.search);
	const jsonParams = Object.fromEntries(urlParams.entries());

	const response = await sendHttpRequest(
		'POST',
		'/page',
		{ path: path, parameters: jsonParams },
		navigate,
		dispatch
	);
	return parseComponentListAsTree(response.data[0]);
}

function parseComponentListAsTree(componentsList: AppComponent[]) {
	const root = [] as AppComponent[];
	const stack = [] as AppComponent[];

	componentsList.forEach((c) => {
		if (!c.i_component.endsWith('_end')) {
			if (stack.length === 0) {
				root.push(c);
			} else {
				const parent = stack[stack.length - 1];
				if (!parent.children) {
					parent.children = [];
				}
				parent.children.push(c);
			}
		}

		if (c.i_component.endsWith('_start')) {
			stack.push(c);
		} else if (c.i_component.endsWith('_end')) {
			stack.pop();
		}
	});

	return root;
}

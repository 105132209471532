import { useCallback, useEffect } from 'react';
import { useNamespacesMapping } from 'sockets/useNamespacesMapping';
import { useDispatch } from 'react-redux';
import { setOpen } from 'state/socketSlice';

export let manager: WebSocket;

export interface RequestMessage {
	namespace: string;
	request: string;
	params: object;
	token: string;
}

export enum Status {
	DONE = 'DONE',
	PENDING = 'PENDING',
	ERROR = 'ERROR'
}

export interface ResponseMessage {
	namespace?: string;
	request: string;
	status: Status;
	data: object | null;
	response: object;
}

export const useSendData = () => {
	return useCallback((data: RequestMessage) => {
		if (manager.readyState !== manager.OPEN) {
			console.warn('Socket not ready');
			return;
		}
		return manager.send(JSON.stringify(data));
	}, []);
};

export const useSockets = () => {
	if (!manager) {
		manager = new WebSocket(process.env.REACT_APP_NODE_API_WS as string);
	}
	const mapping = useNamespacesMapping();
	const dispatch = useDispatch();

	return useEffect(() => {
		manager.onopen = () => {
			console.info('connected to backend');
			dispatch(setOpen(true));
		};

		manager.onmessage = (message) => {
			if (!message) {
				console.warn('invalid message', message);
				return;
			}

			const data: ResponseMessage = JSON.parse(message.data);

			if (data.namespace === undefined) {
				data.namespace = 'default';
			}

			const namespace = data.namespace as keyof typeof mapping;
			if (!mapping[namespace]) {
				console.error('Unknown namespace', message.data.namespace);
				return;
			}
			const handle = mapping[namespace];

			handle(data);
		};
	}, [dispatch, mapping]);
};

import React, { useRef, useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FieldError, FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { ValidationRule } from 'react-hook-form/dist/types/validator';
import { isNumber, roundToDecimals } from 'utils/promotion';
import { DataSaveStatus } from 'utils/types';
import { addThousandSeparator } from 'utils/string';
import { useTranslation } from 'react-i18next';

export default function Input<T extends FieldValues>(props: {
	inputId?: string;
	type: string;
	list?: string;
	name?: string;
	customClass?: string;
	label?: string;
	register?: UseFormRegister<T>;
	error?: FieldError;
	defaultValue?: string;
	required: boolean;
	step?: number;
	min?: number;
	max?: number;
	minLength?: number;
	maxLength?: number;
	pattern?: ValidationRule<RegExp>;
	patternDescription?: string;
	disabled?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	autoComplete?: string;
	customError?: string;
	hideErrorPlaceholder?: boolean;
	rounding?: number;
	setDataSaveStatus?: (s: DataSaveStatus) => void;
	tooltip?: boolean;
	thousandsSeparator?: string | null;
	showRequired?: boolean;
}) {
	const { t } = useTranslation();

	const inputId = props.inputId ?? props.name;

	const labelRef = useRef<HTMLLabelElement>(null);
	const [labelOverflowing, setLabelOverflowing] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [inputChanged, setInputChanged] = useState(false);
	const [lastEvent, setLastEvent] = useState(null as React.ChangeEvent<HTMLInputElement> | null);

	const isLabel = props.label && props.label !== '';

	const thousandsSeparator = props.thousandsSeparator ?? ' ';

	useEffect(() => {
		if (isLabel) {
			const label = labelRef.current;
			if (label) {
				const isOverflow = label.scrollWidth > label.clientWidth;
				setLabelOverflowing(isOverflow);
			}
		}
	}, [props.label, isLabel]);

	let value = props.defaultValue;
	if (props.thousandsSeparator !== null && ['number', 'percentage'].includes(props.type) && value) {
		value = addThousandSeparator(value, thousandsSeparator);
	}
	if (value === '$calc$') {
		value = '';
	}

	useEffect(() => {
		setInputChanged(false);
	}, [props.defaultValue]);

	if (props.register === undefined) {
		return (
			<OverlayTrigger
				overlay={
					props.tooltip ? <Tooltip id={inputId + '-tooltip'}>{props.defaultValue}</Tooltip> : <></>
				}>
				<div
					className={
						(isLabel ? 'form-floating' : '') + (props.type === 'percentage' ? ' percentage' : '')
					}>
					<>
						<input
							disabled={props.disabled}
							type={['number', 'percentage'].includes(props.type) ? 'text' : props.type}
							step={props.step}
							id={inputId}
							value={!inputChanged ? value : inputValue}
							maxLength={props.maxLength}
							list={props.list}
							className={
								'form-control ' +
								(props.customClass ?? '') +
								(props.error || props.customError ? ' is-invalid' : '')
							}
							onFocus={(e) => {
								if (
									props.thousandsSeparator !== null &&
									['number', 'percentage'].includes(props.type)
								) {
									setInputChanged(true);
									setInputValue(e.target.value.replaceAll(thousandsSeparator, ''));
								}
							}}
							onInput={(e) => {
								const event = e as React.ChangeEvent<HTMLInputElement>;
								if (['number', 'percentage'].includes(props.type)) {
									event.target.value = event.target.value.replaceAll(',', '.');
								}
								if (
									!['number', 'percentage'].includes(props.type) ||
									isNumber(event.target.value, props.rounding)
								) {
									setInputChanged(true);
									setInputValue(event.target.value);
									setLastEvent(event);
									props.setDataSaveStatus?.('Save data');

									if (props.onInput) {
										props.onInput(event);
									}
								}
							}}
							autoComplete={props.autoComplete}
							onKeyDown={props.onKeyDown}
							onBlur={() => {
								if (lastEvent !== null) {
									if (
										isNumber(inputValue, props.rounding) &&
										['number', 'percentage'].includes(props.type) &&
										inputValue !== ''
									) {
										if (parseFloat(inputValue) === 0 || isNaN(parseFloat(inputValue))) {
											setInputValue(parseFloat('0').toFixed(props.rounding));
										} else {
											setInputValue(parseFloat(inputValue).toFixed(props.rounding));
										}
									}
									if (['number', 'percentage'].includes(props.type)) {
										if (lastEvent.target.value === '') {
											lastEvent.target.value = '0';
										}
										if (props.rounding) {
											lastEvent.target.value = roundToDecimals(
												parseFloat(lastEvent.target.value),
												props.rounding
											);
										}
									}
									if (lastEvent.target.value !== props.defaultValue) {
										props.onChange?.(lastEvent);
									} else {
										setInputChanged(false);
									}
								} else if (props.defaultValue) {
									setInputChanged(false);
								}
							}}
						/>
						{isLabel && (
							<label ref={labelRef} htmlFor={inputId} tabIndex={-1}>
								{props.label}
								{props.showRequired && props.required && <span className="form-required" />}
							</label>
						)}
						{labelOverflowing && (
							<OverlayTrigger overlay={<Tooltip id={inputId + '-tooltip'}>{props.label}</Tooltip>}>
								<a
									href="#"
									className="tooltip-trigger"
									tabIndex={-1}
									onClick={(e) => e.preventDefault()}
								/>
							</OverlayTrigger>
						)}
					</>
				</div>
			</OverlayTrigger>
		);
	}

	return (
		<div className={isLabel ? 'form-floating' : ''}>
			<input
				{...props.register(props.name as Path<T>, {
					required: props.required,
					min: props.min,
					max: props.max,
					minLength: props.minLength,
					maxLength: props.maxLength,
					pattern: props.pattern
				})}
				disabled={props.disabled}
				type={props.type}
				step={props.step}
				id={inputId}
				defaultValue={value}
				maxLength={props.maxLength}
				className={
					'form-control ' +
					(props.customClass ?? '') +
					(props.error || props.customError ? ' is-invalid' : '')
				}
				onInput={props.onChange}
				autoComplete={props.autoComplete}
				onKeyDown={props.onKeyDown}
			/>
			{isLabel && (
				<label htmlFor={inputId}>
					{props.label}
					{props.showRequired && props.required && <span className="form-required" />}
				</label>
			)}
			{!props.error && !props.customError && (
				<div className="invalid-feedback error-placeholder d-block">&nbsp;</div>
			)}
			{props.error && props.error.type == 'required' && (
				<div className="invalid-feedback d-block">
					{props.label} {t('general.validation.is_required')}
				</div>
			)}
			{props.error && props.error.type == 'min' && (
				<div className="invalid-feedback">
					{props.label} must be at least {props.min}.
				</div>
			)}
			{props.error && props.error.type == 'max' && (
				<div className="invalid-feedback">
					{props.label} must be at most {props.max}.
				</div>
			)}
			{props.error && props.error.type == 'minLength' && (
				<div className="invalid-feedback">
					{props.label} must be at least {props.minLength} characters long.
				</div>
			)}
			{props.error && props.error.type == 'maxLength' && (
				<div className="invalid-feedback">
					{props.label} must be at most {props.maxLength} characters long.
				</div>
			)}
			{props.customError && <div className="invalid-feedback">{props.customError}</div>}
			{props.error && props.error.type == 'pattern' && (
				<div className="invalid-feedback">
					{props.label} {props.patternDescription}.
				</div>
			)}
		</div>
	);
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AppComponent, ComponentDefinition } from 'utils/components';
import { AgGridReact } from 'ag-grid-react';
import {
	ColDef,
	ColumnMovedEvent,
	ColumnResizedEvent,
	FilterChangedEvent,
	SortChangedEvent
} from 'ag-grid-community';
import { format as formatDate } from 'date-fns';
import { useForm } from 'react-hook-form';
import 'assets/scss/grid.css';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Loading from 'components/parts/Loading';
import { trimAtSign } from 'utils/string';
import Input from 'components/Forms/FormComponents/Input';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import DynamicComponent from 'components/DynamicComponent';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import FileDownload from 'js-file-download';
import { RootState } from 'state/store';
import {
	emptyGridFilter,
	emptyGridInternalFilter,
	emptyGridSorting,
	setGridFilter,
	setGridInternalFilter,
	setGridSorting
} from 'state/gridFilterSlice';

type ColumnDefinition = {
	i_tvf_col: string;
	n_tvf_col: string;
	inactive: string | null;
	i_table_valued_function: string;
	i_col_type: string;
	col_type_system: string;
	col_length: number;
	col_precision: number | null;
	col_scale: number | null;
	alignment: 'RIGHT' | null;
	thousands_separator: boolean;
	i_component: string;
	translate: boolean;
	default_width: number;
	editable: boolean;
	column_position: number;
	column_hidden: boolean;
	column_width: number;
};

type FilterDefinition = {
	i_component: string;
	i_col_type: string;
	i_table_valued_function_parameter: string;
	n_table_valued_function_parameter: string;
	n_filter: string;
	parameter_length: string | null;
	parameter_order: string | null;
	parameter_precision: string | null;
	parameter_scale: string | null;
	parameter_type_system: string;
	default_value: string | null;
	visible_for_user: boolean;
};

function parseData(data: object[], columns: ColDef[]) {
	const numericColumns = columns.filter((c) => c.cellDataType === 'number').map((c) => c.field);
	const dateColumns = columns.filter((c) => c.cellDataType === 'date').map((c) => c.field);

	return JSON.parse(JSON.stringify(data), (key, value) => {
		if (value === null) {
			return null;
		}
		if (numericColumns.includes(key)) {
			return parseFloat(value);
		} else if (dateColumns.includes(key)) {
			return new Date(value);
		}
		return value;
	});
}

function parseFilters(filters: FilterDefinition[], t: TFunction<string>) {
	return filters
		.filter((filter) => filter.visible_for_user)
		.map((filter) => {
			filter.n_table_valued_function_parameter = trimAtSign(
				filter.n_table_valued_function_parameter
			);
			filter.n_filter = t('grid.filter.' + filter.n_table_valued_function_parameter);

			return filter;
		})
		.sort((a, b) => parseInt(a.parameter_order ?? '0') - parseInt(b.parameter_order ?? '0'));
}

export default function Grid(props: {
	component: AppComponent;
	definition: ComponentDefinition | undefined;
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [rowDataRaw, setRowDataRaw] = useState([] as object[]);
	const [rowData, setRowData] = useState([] as object[] | null);
	const [columnDefinitionsRaw, setColumnDefinitionsRaw] = useState([] as ColumnDefinition[]);
	const [colDefs, setColDefs] = useState(null as ColDef[] | null);
	const [filterDefs, setFilterDefs] = useState(null as FilterDefinition[] | null);
	const [loading, setLoading] = useState(false);

	const gridIdentifier = props.component.datasource_name ?? '';

	const filterDataObject = useSelector((state: RootState) => state.gridFilter.filterData);
	const internalFilterDataObject = useSelector(
		(state: RootState) => state.gridFilter.internalFilterData
	);
	const sortingDataObject = useSelector(
		(state: RootState) => state.gridFilter.sortingData[gridIdentifier]
	);

	const { register, handleSubmit, reset } = useForm();

	function onSubmit(data: object) {
		dispatch(setGridFilter({ key: gridIdentifier, data }));
	}

	function onReset() {
		dispatch(emptyGridFilter(gridIdentifier));
		if (!colDefs) {
			return;
		}
		Promise.all(
			colDefs.map(async (col) => {
				if (col.filter && col.colId) {
					dispatch(emptyGridInternalFilter(col.colId));
					const f = await gridRef?.current?.api.getColumnFilterInstance(col.colId);
					f?.setModel(null);
				}
			})
		).then(() => gridRef?.current?.api.onFilterChanged());
		reset();
	}

	const filterData = useMemo(() => {
		return filterDataObject[gridIdentifier] ?? {};
	}, [filterDataObject, gridIdentifier]);

	const ButtonCellRenderer = useCallback(
		(p: { value: string; data: object; colDef: ColDef }) => {
			if (!p.value) return null;

			const def = JSON.parse(p.value)[0];

			let parameters: { [key: string]: any } = {};
			Object.entries(filterData).forEach(([key, value]) => {
				if (value !== null && value !== '') {
					parameters[key] = value;
				}
			});

			if (props.component.datasource_parameters !== null) {
				parameters = { ...parameters, ...JSON.parse(props.component.datasource_parameters) };
			}

			const row_identifiers: { [key: string]: any } = {};
			columnDefinitionsRaw
				.filter(
					(c) =>
						!c.n_tvf_col.includes('i_component__') &&
						![
							'datasource_name',
							'datasource_id',
							'columns_to_insert',
							'created_at',
							'OrderDate'
						].includes(c.n_tvf_col)
				)
				.forEach((c) => {
					row_identifiers[c.n_tvf_col] = p.data[c.n_tvf_col as keyof typeof p.data];
				});

			const component = {
				i_component: def.i_component,
				variables: def.variables,
				datasource_name: props.component.datasource_name,
				datasource_parameters: JSON.stringify(parameters),
				action: p.colDef.colId?.split('.').pop(),
				row_identifiers: row_identifiers
			};

			return (
				<DynamicComponent component={component} has_definitions={false} definition={undefined} />
			);
		},
		[
			columnDefinitionsRaw,
			filterData,
			props.component.datasource_name,
			props.component.datasource_parameters
		]
	);

	const getColumnDefinitions = useCallback(
		(
			columnDefinitions: ColumnDefinition[],
			navigate: NavigateFunction,
			dispatch: Dispatch<UnknownAction>,
			t: TFunction<string>
		) => {
			return columnDefinitions
				.filter((col) => !col.column_hidden)
				.sort((a, b) => a.column_position - b.column_position)
				.map((col) => {
					const sorting = sortingDataObject?.find((s) => s.colId === col.i_tvf_col);
					const def: ColDef = {
						colId: col.i_tvf_col,
						headerName: t('grid.columns.' + col.n_tvf_col),
						width: col.column_width ? col.column_width : col.default_width,
						filter: true,
						editable: col.editable,
						sort: sorting?.sort,
						valueGetter: (params) => {
							return params.data[col.n_tvf_col];
						},
						valueSetter: (params) => {
							if (!col.editable) {
								return false;
							}

							params.data[col.n_tvf_col] = params.newValue;

							let parameters: object = filterData;
							if (props.component.datasource_parameters !== null) {
								parameters = {
									...filterData,
									...JSON.parse(props.component.datasource_parameters)
								};
							}

							const row_identifiers: { [key: string]: any } = {};

							columnDefinitionsRaw
								.filter(
									(c) =>
										c.n_tvf_col !== col.n_tvf_col &&
										!c.n_tvf_col.includes('i_component__') &&
										!['datasource_name', 'datasource_id', 'columns_to_insert'].includes(c.n_tvf_col)
								)
								.forEach((c) => {
									row_identifiers[c.n_tvf_col] =
										params.data[c.n_tvf_col as keyof typeof params.data];
								});

							const data = {
								datasource_name: props.component.datasource_name,
								parameters: parameters,
								row_identifiers: row_identifiers,
								column_name: col.n_tvf_col,
								new_value: params.newValue
							};

							sendHttpRequest('POST', '/grid/edit', data, navigate, dispatch).then((response) => {
								if (params.data[params.data.datasource_id] === null) {
									params.data[params.data.datasource_id] =
										response.data[0][0][params.data.datasource_id];
								}
							});

							return true;
						}
					};
					if (col.n_tvf_col.includes('i_component')) {
						def.cellRenderer = ButtonCellRenderer;
						def.filter = false;
					}

					if (col.i_col_type === 'NUMERIC') {
						def.type = 'numericColumn';
						def.cellDataType = 'number';
						def.valueFormatter = (params: { value: number }) => {
							if (!params.value) {
								return '';
							}
							if (col.thousands_separator) {
								return params.value.toLocaleString('en-US', {
									maximumFractionDigits: col.col_scale ?? 0,
									minimumFractionDigits: col.col_scale ?? 0
								});
							} else {
								return params.value.toFixed(col.col_scale ?? 0);
							}
						};
					} else if (col.alignment === 'RIGHT') {
						def.type = 'rightAligned';
					} else if (['TIME', 'DATETIME', 'DATE'].includes(col.i_col_type)) {
						def.filter = 'agDateColumnFilter';
						def.cellDataType = 'date';
						def.valueFormatter = (params: { value: Date | null }) => {
							if (params.value === null) {
								return '';
							}
							let format = '';
							if (col.i_col_type.includes('DATE')) {
								format += 'yyyy.MM.dd';
							}
							if (col.i_col_type.includes('TIME')) {
								format += ' HH:mm:ss';
							}
							return formatDate(params.value, format);
						};
					} else if (col.i_col_type === 'BIT') {
						def.cellDataType = 'boolean';
					}

					return def;
				});
		},
		[
			sortingDataObject,
			ButtonCellRenderer,
			columnDefinitionsRaw,
			filterData,
			props.component.datasource_name,
			props.component.datasource_parameters
		]
	);

	useEffect(() => {
		setLoading(true);
		if (props.component.datasource_name !== null) {
			let parameters: object = filterData;
			if (props.component.datasource_parameters !== null) {
				parameters = {
					...filterData,
					...JSON.parse(props.component.datasource_parameters)
				};
			}
			const dataRequest = sendHttpRequest(
				'POST',
				'/grid/data',
				{ datasource_name: props.component.datasource_name, parameters: parameters },
				navigate,
				dispatch
			);
			const definitionRequest = sendHttpRequest(
				'POST',
				'/grid/column-definitions',
				{ datasource_name: props.component.datasource_name },
				navigate,
				dispatch
			);
			Promise.all([dataRequest, definitionRequest]).then(([dataResponse, definitionResponse]) => {
				setColumnDefinitionsRaw(definitionResponse.data[0] as ColumnDefinition[]);
				setRowDataRaw(dataResponse.data[0]);
				setFilterDefs(parseFilters(definitionResponse.data[1] as FilterDefinition[], t));
				setLoading(false);
			});
		}
	}, [
		filterData,
		gridIdentifier,
		props.component.datasource_name,
		navigate,
		props.component.variables,
		props.component.datasource_parameters,
		dispatch,
		t
	]);

	useEffect(() => {
		const columnDefinitions = getColumnDefinitions(columnDefinitionsRaw, navigate, dispatch, t);
		setRowData(parseData(rowDataRaw, columnDefinitions));
		setColDefs(columnDefinitions as unknown as ColDef[]);
	}, [columnDefinitionsRaw, dispatch, navigate, t, rowDataRaw, getColumnDefinitions]);

	const gridRef = useRef<AgGridReact>(null);

	const onColumnMoved = useCallback((e: ColumnMovedEvent) => {
		console.log('Event Column Moved', e);
	}, []);

	const onColumnResized = useCallback((e: ColumnResizedEvent) => {
		console.log('Event Column Resized', e);
	}, []);

	const onFilterChanged = useCallback(
		(e: FilterChangedEvent) => {
			const columns = e.columns;
			if (columns.length > 0) {
				const colID = columns[0].getColId();
				dispatch(setGridInternalFilter({ key: colID, data: e.api.getColumnFilterModel(colID) }));
			}
		},
		[dispatch]
	);

	const onSortChanged = useCallback(
		(e: SortChangedEvent) => {
			const columns = e.columns;
			if (columns && columns.length > 0) {
				columns.forEach((column) => {
					dispatch(
						setGridSorting({
							gridIdentifier,
							data: { colId: column.getColId(), sort: column.getSort() }
						})
					);
				});
			}
		},
		[dispatch, gridIdentifier]
	);

	function applyFilters() {
		const api = gridRef?.current?.api;
		if (!api) {
			return;
		}
		Promise.all(
			Object.entries(internalFilterDataObject)
				.filter(([key]) => key.startsWith(gridIdentifier))
				.map(([key, value]) =>
					api.getColumnFilterInstance(key).then((f) => {
						f?.setModel(value);
					})
				)
		).then(() => api.onFilterChanged());
	}

	function exportToXlsx() {
		let parameters: object = filterData;
		if (props.component.datasource_parameters !== null) {
			parameters = {
				...filterData,
				...JSON.parse(props.component.datasource_parameters)
			};
		}

		sendHttpRequest(
			'POST',
			'/grid/export',
			{ datasource_name: props.component.datasource_name, parameters: parameters },
			navigate,
			dispatch,
			'blob'
		).then((response) => {
			const filename = response.headers['content-disposition'].split('"')[1];
			FileDownload(response.data, filename);
		});
	}

	return (
		<>
			<div className="d-flex justify-content-between">
				{filterDefs && filterDefs.length !== 0 && (
					<form
						className="filter-form"
						onSubmit={handleSubmit(onSubmit)}
						onReset={onReset}
						noValidate>
						<div className="d-flex flex-wrap">
							{filterDefs?.map((filter) => (
								<div key={filter.i_table_valued_function_parameter} className="filter-item me-3">
									<Input
										name={filter.n_table_valued_function_parameter}
										label={filter.n_filter}
										type="text"
										required={false}
										register={register}
										defaultValue={
											filterData[
												filter.n_table_valued_function_parameter as keyof typeof filterData
											] ??
											filter.default_value ??
											''
										}
									/>
								</div>
							))}
							<button type="submit" className="btn btn-primary me-2">
								<i className="bi bi-search"></i>
							</button>
							<button type="reset" className="btn btn-light">
								<i className="bi bi-arrow-counterclockwise"></i>
							</button>
						</div>
					</form>
				)}
				<div>
					<button type="button" className="btn btn-outline-primary" onClick={exportToXlsx}>
						<i className="me-2 bi bi-download" />
						.xlsx
					</button>
				</div>
			</div>

			<div className="ag-theme-custom mt-2 mb-4">
				<AgGridReact
					ref={gridRef}
					rowData={rowData}
					columnDefs={colDefs}
					domLayout="autoHeight"
					loadingOverlayComponent={Loading}
					pagination={!!rowData && rowData.length > 0}
					paginationPageSize={20}
					loading={loading}
					onColumnMoved={onColumnMoved}
					onColumnResized={onColumnResized}
					onFilterChanged={onFilterChanged}
					onSortChanged={onSortChanged}
					onFirstDataRendered={applyFilters}
				/>
			</div>
		</>
	);
}

import React from 'react';
import { DataSaveStatus, Product, PromotionStructure } from 'utils/types';
import PromotionProduct from 'components/Forms/Promotion/PromotionProduct';
import PromotionTemplateSummary from 'components/Forms/Promotion/PromotionTemplateSummary';
import { useTranslation } from 'react-i18next';

export default function PromotionTemplate(props: {
	template: string;
	structures: PromotionStructure[];
	products: Product[];
	allProducts: Product[];
	index: number;
	viewLevel: 'stock_items' | 'products' | 'summary';
	addProduct: (template: string) => void;
	i_business: number;
	countries: string[];
	omitColumns: string[];
	setOmitColumns: (omitColumns: string[]) => void;
	grouping: string;
	setDataSaveStatus: (s: DataSaveStatus) => void;
	removeProduct: (productId: string, i_template: string) => void;
	listWeekFrom: string;
	listWeekTo: string;
	allGroups: string[];
	editDisabledByStatus: boolean;
	canClose: boolean;
	listStatus: string;
	iList: number;
	triggerDataRefresh: () => void;
}) {
	const { t } = useTranslation();

	function sumPrice(product: Product) {
		return product.items.reduce(
			(a, b) => a + parseFloat((b.srp ?? 0).toString()) * parseInt(b.quantity.toString()),
			0
		);
	}

	function templateSumPrice() {
		let sum = 0.0;
		for (const p of props.products) {
			sum += sumPrice(p);
		}

		return sum;
	}

	function templateSumRetailPrice() {
		return props.products.reduce((a, b) => a + parseFloat((b.retail_price ?? 0).toString()), 0);
	}

	function getValue(tab_key: string | null, col_key: string, productIndex: number, c?: string) {
		if (tab_key == 'tab_GP_calculator') {
			return '$calc$';
		}
		const product = props.products[productIndex];
		if (c) {
			const country = product.countries?.find((pc) => pc.i_country === c);
			if (!country) {
				return '';
			}
			if (col_key == 'product_name') {
				return country?.product_name ?? '';
			}
			const tab = (country[tab_key as keyof typeof country] as object[])[0];
			return tab[col_key as keyof typeof tab] ?? '';
		}
		const values = product.countries?.map((pc) => {
			const tab = (pc[tab_key as keyof typeof pc] as object[])[0];
			return tab[col_key as keyof typeof tab] ?? '';
		});
		if (values?.length && values.every((v) => v === values[0])) {
			return values[0] ?? '';
		}
		return '';
	}

	function isProductAddingEnabled() {
		return (
			!props.editDisabledByStatus &&
			['products', 'stock_items'].includes(props.viewLevel) &&
			props.grouping === 'template' &&
			props.listStatus == 'BUILD'
		);
	}

	return (
		<section className={'template-wrapper ' + (props.viewLevel === 'summary' ? 'pt-3' : 'pb-3')}>
			{props.viewLevel !== 'summary' && (
				<div className="template-name-wrapper">
					<div className="template-name bg-primary color-primary-shade">
						<h2>{props.template}</h2>
					</div>
				</div>
			)}
			{props.viewLevel == 'summary' && <div className="template-name bg-white"></div>}
			<div className="ms-2">
				{props.viewLevel == 'summary' && (
					<>
						<PromotionTemplateSummary
							data={props.products}
							allProducts={props.allProducts}
							template={props.template}
							srpTemplateTotal={templateSumPrice()}
							basePriceTemplateTotal={0}
							retailPriceTemplateTotal={templateSumRetailPrice()}
							showTemplate={props.viewLevel === 'summary'}
							getValue={getValue}
							structures={props.structures}
							countries={props.countries}
							omitColumns={props.omitColumns}
							setOmitColumns={props.setOmitColumns}
							listWeekFrom={props.listWeekFrom}
							listWeekTo={props.listWeekTo}
							sumPrice={sumPrice}
							channel="Direct selling"
							mainCluster={props.products[0]?.main_cluster}
							gpGroup={props.products[0]?.GP_group}
							usage={props.products[0]?.i_usg}
							grouping={props.grouping}
							allGroups={props.allGroups}
						/>
						<PromotionTemplateSummary
							data={props.products}
							allProducts={props.allProducts}
							template={props.template}
							srpTemplateTotal={templateSumPrice()}
							basePriceTemplateTotal={0}
							retailPriceTemplateTotal={templateSumRetailPrice()}
							showTemplate={props.viewLevel === 'summary'}
							getValue={getValue}
							structures={props.structures}
							countries={props.countries}
							omitColumns={props.omitColumns}
							setOmitColumns={props.setOmitColumns}
							listWeekFrom={props.listWeekFrom}
							listWeekTo={props.listWeekTo}
							sumPrice={sumPrice}
							channel="E-commerce"
							mainCluster={props.products[0]?.main_cluster}
							gpGroup={props.products[0]?.GP_group}
							usage={props.products[0]?.i_usg}
							grouping={props.grouping}
							allGroups={props.allGroups}
						/>
					</>
				)}
				{['products', 'stock_items'].includes(props.viewLevel) &&
					props.products.map((product, index) => (
						<PromotionProduct
							key={product.id}
							index={index}
							product={product}
							showStockItems={props.viewLevel === 'stock_items'}
							retail_price={product.retail_price?.toString()}
							template={props.template}
							i_business={props.i_business}
							structures={props.structures}
							sumPrice={sumPrice}
							countries={props.countries}
							omitColumns={props.omitColumns}
							setDataSaveStatus={props.setDataSaveStatus}
							removeProduct={props.removeProduct}
							listWeekFrom={props.listWeekFrom}
							listWeekTo={props.listWeekTo}
							editDisabledByStatus={props.editDisabledByStatus}
							canClose={props.canClose}
							listStatus={props.listStatus}
							iList={props.iList}
							triggerDataRefresh={props.triggerDataRefresh}
						/>
					))}
				{isProductAddingEnabled() && (
					<div className="add-product mt-3 ms-2">
						<button
							onClick={() => props.addProduct(props.template)}
							type="button"
							className="btn btn-outline-primary border-0">
							<i className="bi bi-plus-lg me-1"></i>
							{t('promotion.fields.add_product')}
						</button>
					</div>
				)}
			</div>
		</section>
	);
}

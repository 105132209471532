import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Router from 'router/Router';
import { isUserLoggedIn, sendHttpRequest } from 'utils/httpRequestManager';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { setComponents } from 'state/componentSlice';
import { useSockets } from 'sockets/sockets';
import NotificationContainer from 'components/parts/NotificationContainer';
import i18n, { setDispatcher } from 'i18n';
import { setTranslations } from 'state/translationSlice';
import ErrorPage from 'pages/ErrorPage';
import { AxiosError } from 'axios';
import Loading from 'components/parts/Loading';

export default function App() {
	useSockets();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const components = useSelector((state: RootState) => state.components.components);

	const resources = useSelector((state: RootState) => state.translation);

	const [translationsLoaded, setTranslationsLoaded] = useState(false);

	const [error, setError] = useState(
		null as null | {
			message: string;
			stack: string;
		}
	);

	let isError = false;

	useEffect(() => {
		if (
			process.env.REACT_APP_SECRET_TOKEN !== undefined &&
			error &&
			!(error instanceof AxiosError)
		) {
			sendHttpRequest(
				'POST',
				'/error-email',
				{ message: error.message, stack: error.stack, url: window.location.href },
				navigate,
				dispatch,
				undefined,
				true
			).catch(() => {
				console.error('API down');
			});
		}
	}, [dispatch, error, navigate]);

	setDispatcher(dispatch);

	useEffect(() => {
		Object.entries(resources).forEach(([lang, resource]) => {
			i18n.addResourceBundle(lang, 'translation', resource.translations, undefined, true);
		});
		translationsLoaded || setTranslationsLoaded(true);
	}, [resources, translationsLoaded]);

	useEffect(() => {
		if (isUserLoggedIn() && components.length === 0) {
			sendHttpRequest('GET', '/components', null, navigate, dispatch).then((response) => {
				dispatch(setComponents(response.data[0]));
			});
		}
	}, [components, dispatch, navigate]);

	useEffect(() => {
		const langs = ['en'];
		if (i18n.language.toLowerCase() !== 'en') {
			langs.push(i18n.language.toLowerCase());
		}
		langs.forEach((lang) => {
			const now = new Date().getTime();
			if (
				resources[lang] == undefined ||
				resources[lang].last_update == null ||
				now - new Date(resources[lang].last_update ?? '').getTime() > 1000 * 60 * 15 // 15 minutes
			) {
				let url = '/translations/v2/' + lang;
				if (resources[lang] !== undefined) {
					url += '?hash=' + resources[lang].hash;
				}
				sendHttpRequest('GET', url, null, navigate, dispatch).then((response) => {
					if (response.data.translations !== undefined) {
						dispatch(
							setTranslations({
								locale: lang,
								translations: response.data.translations,
								hash: response.data.hash
							})
						);
					}
				});
			}
		});
	}, [resources, dispatch, navigate]);

	useEffect(() => {
		setError(null);
	}, [location]);

	useEffect(() => {
		function onError(event: ErrorEvent) {
			if (!isError) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				isError = true;
				setError({ message: event.error.message, stack: event.error.stack });
			}
		}

		function onUnhandledRejection(event: PromiseRejectionEvent) {
			if (!isError) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				isError = true;
				setError({ message: event.reason.message, stack: event.reason.stack });
			}
		}

		window.addEventListener('error', onError);
		window.addEventListener('unhandledrejection', onUnhandledRejection);

		return () => {
			window.removeEventListener('error', onError);
			window.removeEventListener('unhandledrejection', onUnhandledRejection);
		};
	}, []);

	return (
		<div className="App">
			<Header />
			<main className="container-fluid px-4">
				<>
					{!translationsLoaded ? (
						<Loading />
					) : (
						<>
							{!error ? (
								<>
									<Router />

									<NotificationContainer />
								</>
							) : (
								<ErrorPage />
							)}
						</>
					)}
				</>
			</main>
		</div>
	);
}

import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Loading() {
	const { t } = useTranslation();

	return (
		<div className="d-flex justify-content-center loading-spinner">
			<div className="spinner-border" role="status">
				<span className="visually-hidden">{t('general.phrases.loading')}...</span>
			</div>
		</div>
	);
}

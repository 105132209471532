import React from 'react';
import { createSearchParams, Link, To } from 'react-router-dom';

export default function NavButton(props: {
	class: string;
	path: string;
	pathParams: any;
	children: string | null | undefined;
}) {
	const to: To = { pathname: props.path };

	if (props.pathParams) {
		to.search = createSearchParams(props.pathParams).toString();
	}

	return (
		<Link to={to} className={props.class}>
			{props.children}
		</Link>
	);
}

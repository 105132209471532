import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PetitionState {
	num_sta: number;
	num_signed: number;
}

const initialState: PetitionState = {
	num_sta: 0,
	num_signed: 0
};

const slice = createSlice({
	name: 'petition',
	initialState,
	reducers: {
		setStats(state, action: PayloadAction<{ num_sta: number; num_signed: number }>) {
			state.num_sta = action.payload.num_sta;
			state.num_signed = action.payload.num_signed;
		}
	}
});

export default slice.reducer;
export const { setStats } = slice.actions;

import React, { Fragment, useCallback, useMemo } from 'react';
import Input from 'components/Forms/FormComponents/Input';
import { Product, PromotionColumn, PromotionStructure } from 'utils/types';
import { calculateValue, getBaseColumns } from 'utils/promotion';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';

export default function PromotionTemplateSummary(props: {
	data: Product[];
	allProducts: Product[];
	template: string;
	srpTemplateTotal: number;
	basePriceTemplateTotal: number;
	retailPriceTemplateTotal: number;
	showTemplate: boolean;
	getValue: (tab_key: string, col_key: string, productIndex: number, c?: string) => string;
	structures: PromotionStructure[];
	countries: string[];
	omitColumns: string[];
	setOmitColumns: (omitColumns: string[]) => void;
	listWeekFrom: string;
	listWeekTo: string;
	sumPrice: (product: Product, c?: string) => number;
	channel?: string;
	mainCluster?: string;
	gpGroup?: string;
	usage?: string;
	grouping: string;
	allGroups: string[];
}) {
	const tabs = useMemo(() => {
		return (
			props.structures
				.find((s) => props.data[0]?.i_template === s.i_template)
				?.tabs.filter((t) => t.key === 'tab_GP_calculator') ?? []
		);
	}, [props.data, props.structures]);

	const allCountries = useSelector((state: RootState) => state.user.allCountries);

	const calculateColumnSum = useCallback(
		(tab_key: string, col: PromotionColumn, all = false): number => {
			if (col.key.endsWith('GP%')) {
				const marginColName = col.key === 'GP%' ? 'margin' : 'eshop_margin';
				const companySalesColName = col.key === 'GP%' ? 'company_sales' : 'eshop_company_sales';

				const tab = tabs.find((t) => t.key === tab_key);
				const marginCol = tab?.columns.find((c) => c.key === marginColName);
				const companySalesCol = tab?.columns.find((c) => c.key === companySalesColName);

				if (marginCol && companySalesCol) {
					const marginSum = calculateColumnSum(tab_key, marginCol);
					const companySalesSum = calculateColumnSum(tab_key, companySalesCol);
					return companySalesSum === 0 ? 0 : (marginSum / companySalesSum) * 100;
				}
				return 0;
			}

			if (props.countries.length) {
				return props.countries
					.map((c) =>
						(all ? props.allProducts : props.data).reduce((sum, p, i) => {
							if (props.getValue(tab_key, col.key, i, c) !== '$calc$') {
								return (
									parseFloat(
										props.getValue(tab_key, col.key, i, c) &&
											props.getValue(tab_key, col.key, i, c) !== ''
											? props.getValue(tab_key, col.key, i, c)
											: '0'
									) + sum
								);
							} else {
								const baseColumn = getBaseColumns(
									allCountries,
									p,
									props.sumPrice,
									props.listWeekFrom,
									props.listWeekTo
								).find((bc) => bc.country === c);
								return (
									(baseColumn && col.definition
										? parseFloat(calculateValue(baseColumn, col.definition, col.decimal).toString())
										: 0) + sum
								);
							}
						}, 0)
					)
					.reduce((sum, c) => sum + c, 0);
			}

			return 0;
		},
		[allCountries, props, tabs]
	);

	const groups = [props.channel, props.mainCluster, props.gpGroup, props.usage];

	function isAnyRelevantGroupDefined() {
		return groups
			.filter((g, gi) => gi < props.allGroups.indexOf(props.grouping))
			.some((g) => g !== undefined);
	}

	function showColumn(tab_key: string, col: PromotionColumn) {
		return (
			['number', 'percentage'].includes(col.type) &&
			!col.key.endsWith('week_from') &&
			!col.key.endsWith('week_to') &&
			!props.omitColumns.includes(col.key) &&
			col.key !== 'pcs_qty' &&
			(col.key !== 'sg_a_cost' || calculateColumnSum(tab_key, col) !== 0)
		);
	}

	function checkAllValuesEqualToZeroEshop() {
		let nonZeroFlag = false;

		if (tabs) {
			tabs.forEach((t) => {
				t.columns
					.filter(
						(c) =>
							(props.channel === 'Direct selling' && !c.key.startsWith('eshop')) ||
							(props.channel === 'E-commerce' && c.key.startsWith('eshop'))
					)
					.forEach((c) => {
						if (showColumn(t.key, c) && calculateColumnSum(t.key, c) !== 0) {
							nonZeroFlag = true;
						}
					});
			});
		}

		return !nonZeroFlag;
	}

	if (props.channel === 'E-commerce' && checkAllValuesEqualToZeroEshop()) return <></>;

	return (
		<div className="row mt-3 mx-0 ms-1 promotion-template-summary">
			<div className="promotion-sticky-left summary-section ps-4 pe-2 pb-2">
				<div className="p-3">
					{props.grouping !== 'channel' && isAnyRelevantGroupDefined() && (
						<div className="w-100">
							<nav className="breadcrumb-nav-summary">
								<ol className="breadcrumb mb-2">
									{groups
										.filter((g, gi) => gi < props.allGroups.indexOf(props.grouping))
										.map((group, group_i) => (
											<Fragment key={group_i}>
												{group && <li className="breadcrumb-item active">{group}</li>}
											</Fragment>
										))}
								</ol>
							</nav>
						</div>
					)}

					<div className="d-flex align-items-center">
						{props.showTemplate && (
							<div className="summary-template-name">
								<h2>
									{props.showTemplate && props.grouping !== 'channel'
										? props.template
										: props.showTemplate
											? props.channel
											: ''}
								</h2>
							</div>
						)}

						<div className="d-flex">
							{tabs.map((t, i) => (
								<Fragment key={i}>
									{t.columns
										.filter(
											(c) =>
												(props.channel === 'Direct selling' && !c.key.startsWith('eshop')) ||
												(props.channel === 'E-commerce' && c.key.startsWith('eshop'))
										)
										.map((col, col_index) => (
											<Fragment key={col_index}>
												{showColumn(t.key, col) && (
													<div className="position-relative">
														<div className="col px-1">
															<Input
																label={col.name}
																name={props.template + '.sum.' + i + '.' + col.key}
																type={col.type}
																customClass="text-end"
																step={0.01}
																defaultValue={calculateColumnSum(t.key, col).toFixed(2)}
																required={false}
																disabled={true}
															/>
															{col.type === 'percentage' && <div>%</div>}
														</div>
													</div>
												)}
											</Fragment>
										))}
								</Fragment>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

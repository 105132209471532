export function capitalizeFirstLetter(s: string): string {
	return s.charAt(0).toUpperCase() + s.slice(1);
}

export function humanizeString(s: string): string {
	if (s.startsWith('i_')) {
		s = s.slice(2);
	}
	return capitalizeFirstLetter(s.replace(/_/g, ' ').trim());
}

export function trimAtSign(s: string): string {
	return s.replace('@', '');
}

export function randomHash(): string {
	return crypto.randomUUID();
}

export function addThousandSeparator(n: string | number, separator = ' '): string {
	n = n.toString();
	return n.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ComponentDefinition } from 'utils/components';

export interface ComponentsState {
	components: ComponentDefinition[];
}

const initialState: ComponentsState = {
	components: []
};

const slice = createSlice({
	name: 'component',
	initialState,
	reducers: {
		setComponents(state, action: PayloadAction<ComponentDefinition[]>) {
			state.components = action.payload;
		}
	}
});

export default slice.reducer;
export const { setComponents } = slice.actions;

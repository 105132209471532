import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginResponse, User } from 'utils/auth';

export const authItemKey = 'authItem';

export interface UserState {
	allCountries: string[];
	user: User | null;
}

const initialState: UserState = {
	allCountries: ['AT', 'DE'],
	user: localStorage.getItem(authItemKey)
		? (JSON.parse(localStorage.getItem(authItemKey) as string) as LoginResponse).user
		: null
};

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser(state, action: PayloadAction<User>) {
			state.user = action.payload;
		},
		setRole(state, action: PayloadAction<string>) {
			if (state.user) {
				state.user.role = action.payload;
			}
		},
		unsetUser(state) {
			state.user = null;
		},
		setAllCountries(state, action: PayloadAction<string[]>) {
			state.allCountries = action.payload;
		}
	}
});

export default slice.reducer;
export const { setUser, setRole, unsetUser, setAllCountries } = slice.actions;
